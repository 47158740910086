/******************************************************************
Theme Name: Travel 
Description: 
Author: Colorlib
Author URI: 
Version: 1.0
Created: 
******************************************************************/
/*------------------------------------------------------------------


-------------------------------------------------------------------*/
/*------------------------------------------------------------------
[Table of contents]

1.  Basic Styles
	1.1	Variables
	1.2	Mixins
	1.3	Flexbox
	1.4	Reset
2.  Elements
3.  Menu Style 
4.  Home Page Style
5.  About Page Style
6.  Department Page Style
7.  Blog Page Style
8.  Contact Page Style
9.  Footer Style

-------------------------------------------------------------------*/
/* =================================== */
/*  Font Family and Colors Styles
/* =================================== */
/*

$primary-font	: 'Poppins', sans-serif;
---------------------------------------------

$primary-color	: #f8b600;
$title-color	: #222222;
$text-color		: #777777;

$white			: #fff;
$offwhite		: #f9f9ff;
$black			: #222;

*/
/* =================================== */
/*  Basic Style 
/* =================================== */
::-moz-selection {
  /* Code for Firefox */
  background-color: #f8b600;
  color: #fff;
}

::selection {
  background-color: #f8b600;
  color: #fff;
}

::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #777777;
  font-weight: 300;
}

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #777777;
  opacity: 1;
  font-weight: 300;
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #777777;
  opacity: 1;
  font-weight: 300;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #777777;
  font-weight: 300;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #777777;
  font-weight: 300;
}

body {
  color: #777777;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.625em;
  position: relative;
}

ol, ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

select {
  display: block;
}

figure {
  margin: 0;
}

a {
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

iframe {
  border: 0;
}

a, a:focus, a:hover {
  text-decoration: none;
  outline: 0;
}

.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn.focus:active,
.btn:active:focus,
.btn:focus {
  text-decoration: none;
  outline: 0;
}

.card-panel {
  margin: 0;
  padding: 60px;
}

/**
 *  Typography
 *
 **/
.btn i, .btn-large i, .btn-floating i, .btn-large i, .btn-flat i {
  font-size: 1em;
  line-height: inherit;
}

.gray-bg {
  background: #f9f9ff;
}

h1, h2, h3,
h4, h5, h6 {
  font-family: "Poppins", sans-serif;
  color: #222222;
  line-height: 1.2em !important;
  margin-bottom: 0;
  margin-top: 0;
  font-weight: 600;
}

.h1, .h2, .h3,
.h4, .h5, .h6 {
  margin-bottom: 0;
  margin-top: 0;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: #222222;
}

h1, .h1 {
  font-size: 36px;
}

h2, .h2 {
  font-size: 30px;
}

h3, .h3 {
  font-size: 24px;
}

h4, .h4 {
  font-size: 18px;
}

h5, .h5 {
  font-size: 16px;
}

h6, .h6 {
  font-size: 14px;
  color: #222222;
}

td, th {
  border-radius: 0px;
}

/**
 * For modern browsers
 * 1. The space content is one way to avoid an Opera bug when the
 *    contenteditable attribute is included anywhere else in the document.
 *    Otherwise it causes space to appear at the top and bottom of elements
 *    that are clearfixed.
 * 2. The use of `table` rather than `block` is only necessary if using
 *    `:before` to contain the top-margins of child elements.
 */
.clear::before, .clear::after {
  content: " ";
  display: table;
}

.clear::after {
  clear: both;
}

.fz-11 {
  font-size: 11px;
}

.fz-12 {
  font-size: 12px;
}

.fz-13 {
  font-size: 13px;
}

.fz-14 {
  font-size: 14px;
}

.fz-15 {
  font-size: 15px;
}

.fz-16 {
  font-size: 16px;
}

.fz-18 {
  font-size: 18px;
}

.fz-30 {
  font-size: 30px;
}

.fz-48 {
  font-size: 48px !important;
}

.fw100 {
  font-weight: 100;
}

.fw300 {
  font-weight: 300;
}

.fw400 {
  font-weight: 400 !important;
}

.fw500 {
  font-weight: 500;
}

.f700 {
  font-weight: 700;
}

.fsi {
  font-style: italic;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-35 {
  margin-top: 35px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-70 {
  margin-top: 70px;
}

.mt-80 {
  margin-top: 80px;
}

.mt-100 {
  margin-top: 100px;
}

.mt-120 {
  margin-top: 120px;
}

.mt-150 {
  margin-top: 150px;
}

.ml-0 {
  margin-left: 0 !important;
}

.ml-5 {
  margin-left: 5px !important;
}

.ml-10 {
  margin-left: 10px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-30 {
  margin-left: 30px;
}

.ml-50 {
  margin-left: 50px;
}

.mr-0 {
  margin-right: 0 !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.mr-15 {
  margin-right: 15px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-30 {
  margin-right: 30px;
}

.mr-50 {
  margin-right: 50px;
}

.mb-0 {
  margin-bottom: 0px;
}

.mb-0-i {
  margin-bottom: 0px !important;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-70 {
  margin-bottom: 70px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mb-90 {
  margin-bottom: 90px;
}

.mb-100 {
  margin-bottom: 100px;
}

.pt-0 {
  padding-top: 0px;
}

.pt-10 {
  padding-top: 10px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-25 {
  padding-top: 25px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-60 {
  padding-top: 60px;
}

.pt-70 {
  padding-top: 70px;
}

.pt-80 {
  padding-top: 80px;
}

.pt-90 {
  padding-top: 90px;
}

.pt-100 {
  padding-top: 100px;
}

.pt-120 {
  padding-top: 120px;
}

.pt-150 {
  padding-top: 150px;
}

.pt-170 {
  padding-top: 170px;
}

.pb-0 {
  padding-bottom: 0px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pb-90 {
  padding-bottom: 90px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pb-120 {
  padding-bottom: 120px;
}

.pb-150 {
  padding-bottom: 150px;
}

.pr-30 {
  padding-right: 30px;
}

.pl-30 {
  padding-left: 30px;
}

.pl-90 {
  padding-left: 90px;
}

.p-40 {
  padding: 40px;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.text-italic {
  font-style: italic;
}

.text-white {
  color: #fff;
}

.text-black {
  color: #000;
}

.transition {
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.section-full {
  padding: 100px 0;
}

.section-half {
  padding: 75px 0;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-rigth {
  text-align: right;
}

.flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}

.inline-flex {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.flex-grow {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -moz-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.flex-wrap {
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.flex-left {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  justify-content: flex-start;
}

.flex-middle {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}

.flex-right {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  justify-content: flex-end;
}

.flex-top {
  -webkit-align-self: flex-start;
  -moz-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.flex-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
}

.flex-bottom {
  -webkit-align-self: flex-end;
  -moz-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
}

.space-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
}

.space-around {
  -ms-flex-pack: distribute;
  -webkit-justify-content: space-around;
  -moz-justify-content: space-around;
  justify-content: space-around;
}

.flex-column {
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.flex-cell {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -moz-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.display-table {
  display: table;
}

.light {
  color: #fff;
}

.dark {
  color: #222;
}

.relative {
  position: relative;
}

.overflow-hidden {
  overflow: hidden;
}

.overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.container.fullwidth {
  width: 100%;
}

.container.no-padding {
  padding-left: 0;
  padding-right: 0;
}

.no-padding {
  padding: 0;
}

.section-bg {
  background: #f9fafc;
}

@media (max-width: 767px) {
  .no-flex-xs {
    display: block !important;
  }
}

.row.no-margin {
  margin-left: 0;
  margin-right: 0;
}

/* =================================== */
/*  Elements Page Styles
/* =================================== */
/*---------- Start Elements Page -------------*/
.whole-wrap {
  background-color: #f9f9ff;
}

.generic-banner {
  margin-top: 60px;
  background-color: #f8b600;
  text-align: center;
}

.generic-banner .height {
  height: 600px;
}

@media (max-width: 767.98px) {
  .generic-banner .height {
    height: 400px;
  }
}

.generic-banner .generic-banner-content h2 {
  line-height: 1.2em;
  margin-bottom: 20px;
}

@media (max-width: 991.98px) {
  .generic-banner .generic-banner-content h2 br {
    display: none;
  }
}

.generic-banner .generic-banner-content p {
  text-align: center;
  font-size: 16px;
}

@media (max-width: 991.98px) {
  .generic-banner .generic-banner-content p br {
    display: none;
  }
}

.generic-content h1 {
  font-weight: 600;
}

.about-generic-area {
  background: #fff;
}

.about-generic-area p {
  margin-bottom: 20px;
}

.white-bg {
  background: #fff;
}

.section-top-border {
  padding: 50px 0;
  border-top: 1px dotted #eee;
}

.switch-wrap {
  margin-bottom: 10px;
}

.switch-wrap p {
  margin: 0;
}

/*---------- End Elements Page -------------*/
.sample-text-area {
  background: #f9f9ff;
  padding: 100px 0 70px 0;
}

.sample-text {
  margin-bottom: 0;
}

.text-heading {
  margin-bottom: 30px;
  font-size: 24px;
}

.typo-list {
  margin-bottom: 10px;
}

@media (max-width: 767px) {
  .typo-sec {
    margin-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .element-wrap {
    margin-top: 30px;
  }
}

b, sup, sub, u, del {
  color: #f8b600;
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 16px;
}

h6 {
  font-size: 14px;
}

h1, h2, h3, h4, h5, h6 {
  line-height: 1.5em;
}

.typography h1, .typography h2, .typography h3, .typography h4, .typography h5, .typography h6 {
  color: #777777;
}

.button-area {
  background: #f9f9ff;
}

.button-area .border-top-generic {
  padding: 70px 15px;
  border-top: 1px dotted #eee;
}

.button-group-area .genric-btn {
  margin-right: 10px;
  margin-top: 10px;
}

.button-group-area .genric-btn:last-child {
  margin-right: 0;
}

.circle {
  border-radius: 20px;
}

.genric-btn {
  display: inline-block;
  outline: none;
  line-height: 40px;
  padding: 0 30px;
  font-size: .8em;
  text-align: center;
  text-decoration: none;
  font-weight: 500;
  cursor: pointer;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.genric-btn:focus {
  outline: none;
}

.genric-btn.e-large {
  padding: 0 40px;
  line-height: 50px;
}

.genric-btn.large {
  line-height: 45px;
}

.genric-btn.medium {
  line-height: 30px;
}

.genric-btn.small {
  line-height: 25px;
}

.genric-btn.radius {
  border-radius: 3px;
}

.genric-btn.circle {
  border-radius: 20px;
}

.genric-btn.arrow {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.genric-btn.arrow span {
  margin-left: 10px;
}

.genric-btn.default {
  color: #222222;
  background: #f9f9ff;
  border: 1px solid transparent;
}

.genric-btn.default:hover {
  border: 1px solid #f9f9ff;
  background: #fff;
}

.genric-btn.default-border {
  border: 1px solid #f9f9ff;
  background: #fff;
}

.genric-btn.default-border:hover {
  color: #222222;
  background: #f9f9ff;
  border: 1px solid transparent;
}

.genric-btn.primary {
  color: #fff;
  background: #f8b600;
  border: 1px solid transparent;
}

.genric-btn.primary:hover {
  color: #f8b600;
  border: 1px solid #f8b600;
  background: #fff;
}

.genric-btn.primary-border {
  color: #f8b600;
  border: 1px solid #f8b600;
  background: #fff;
}

.genric-btn.primary-border:hover {
  color: #fff;
  background: #f8b600;
  border: 1px solid transparent;
}

.genric-btn.success {
  color: #fff;
  background: #4cd3e3;
  border: 1px solid transparent;
}

.genric-btn.success:hover {
  color: #4cd3e3;
  border: 1px solid #4cd3e3;
  background: #fff;
}

.genric-btn.success-border {
  color: #4cd3e3;
  border: 1px solid #4cd3e3;
  background: #fff;
}

.genric-btn.success-border:hover {
  color: #fff;
  background: #4cd3e3;
  border: 1px solid transparent;
}

.genric-btn.info {
  color: #fff;
  background: #38a4ff;
  border: 1px solid transparent;
}

.genric-btn.info:hover {
  color: #38a4ff;
  border: 1px solid #38a4ff;
  background: #fff;
}

.genric-btn.info-border {
  color: #38a4ff;
  border: 1px solid #38a4ff;
  background: #fff;
}

.genric-btn.info-border:hover {
  color: #fff;
  background: #38a4ff;
  border: 1px solid transparent;
}

.genric-btn.warning {
  color: #fff;
  background: #f4e700;
  border: 1px solid transparent;
}

.genric-btn.warning:hover {
  color: #f4e700;
  border: 1px solid #f4e700;
  background: #fff;
}

.genric-btn.warning-border {
  color: #f4e700;
  border: 1px solid #f4e700;
  background: #fff;
}

.genric-btn.warning-border:hover {
  color: #fff;
  background: #f4e700;
  border: 1px solid transparent;
}

.genric-btn.danger {
  color: #fff;
  background: #f44a40;
  border: 1px solid transparent;
}

.genric-btn.danger:hover {
  color: #f44a40;
  border: 1px solid #f44a40;
  background: #fff;
}

.genric-btn.danger-border {
  color: #f44a40;
  border: 1px solid #f44a40;
  background: #fff;
}

.genric-btn.danger-border:hover {
  color: #fff;
  background: #f44a40;
  border: 1px solid transparent;
}

.genric-btn.link {
  color: #222222;
  background: #f9f9ff;
  text-decoration: underline;
  border: 1px solid transparent;
}

.genric-btn.link:hover {
  color: #222222;
  border: 1px solid #f9f9ff;
  background: #fff;
}

.genric-btn.link-border {
  color: #222222;
  border: 1px solid #f9f9ff;
  background: #fff;
  text-decoration: underline;
}

.genric-btn.link-border:hover {
  color: #222222;
  background: #f9f9ff;
  border: 1px solid transparent;
}

.genric-btn.disable {
  color: #222222, 0.3;
  background: #f9f9ff;
  border: 1px solid transparent;
  cursor: not-allowed;
}

.generic-blockquote {
  padding: 30px 50px 30px 30px;
  background: #fff;
  border-left: 2px solid #f8b600;
}

@media (max-width: 991px) {
  .progress-table-wrap {
    overflow-x: scroll;
  }
}

.progress-table {
  background: #fff;
  padding: 15px 0px 30px 0px;
  min-width: 800px;
}

.progress-table .serial {
  width: 11.83%;
  padding-left: 30px;
}

.progress-table .country {
  width: 28.07%;
}

.progress-table .visit {
  width: 19.74%;
}

.progress-table .percentage {
  width: 40.36%;
  padding-right: 50px;
}

.progress-table .table-head {
  display: flex;
}

.progress-table .table-head .serial, .progress-table .table-head .country, .progress-table .table-head .visit, .progress-table .table-head .percentage {
  color: #222222;
  line-height: 40px;
  text-transform: uppercase;
  font-weight: 500;
}

.progress-table .table-row {
  padding: 15px 0;
  border-top: 1px solid #edf3fd;
  display: flex;
}

.progress-table .table-row .serial, .progress-table .table-row .country, .progress-table .table-row .visit, .progress-table .table-row .percentage {
  display: flex;
  align-items: center;
}

.progress-table .table-row .country img {
  margin-right: 15px;
}

.progress-table .table-row .percentage .progress {
  width: 80%;
  border-radius: 0px;
  background: transparent;
}

.progress-table .table-row .percentage .progress .progress-bar {
  height: 5px;
  line-height: 5px;
}

.progress-table .table-row .percentage .progress .progress-bar.color-1 {
  background-color: #6382e6;
}

.progress-table .table-row .percentage .progress .progress-bar.color-2 {
  background-color: #e66686;
}

.progress-table .table-row .percentage .progress .progress-bar.color-3 {
  background-color: #f09359;
}

.progress-table .table-row .percentage .progress .progress-bar.color-4 {
  background-color: #73fbaf;
}

.progress-table .table-row .percentage .progress .progress-bar.color-5 {
  background-color: #73fbaf;
}

.progress-table .table-row .percentage .progress .progress-bar.color-6 {
  background-color: #6382e6;
}

.progress-table .table-row .percentage .progress .progress-bar.color-7 {
  background-color: #a367e7;
}

.progress-table .table-row .percentage .progress .progress-bar.color-8 {
  background-color: #e66686;
}

.single-gallery-image {
  margin-top: 30px;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  background-size: cover !important;
  height: 200px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.single-gallery-image:hover {
  opacity: .8;
}

.list-style {
  width: 14px;
  height: 14px;
}

.unordered-list li {
  position: relative;
  padding-left: 30px;
  line-height: 1.82em !important;
}

.unordered-list li:before {
  content: "";
  position: absolute;
  width: 14px;
  height: 14px;
  border: 3px solid #f8b600;
  background: #fff;
  top: 4px;
  left: 0;
  border-radius: 50%;
}

.ordered-list {
  margin-left: 30px;
}

.ordered-list li {
  list-style-type: decimal-leading-zero;
  color: #f8b600;
  font-weight: 500;
  line-height: 1.82em !important;
}

.ordered-list li span {
  font-weight: 300;
  color: #777777;
}

.ordered-list-alpha li {
  margin-left: 30px;
  list-style-type: lower-alpha;
  color: #f8b600;
  font-weight: 500;
  line-height: 1.82em !important;
}

.ordered-list-alpha li span {
  font-weight: 300;
  color: #777777;
}

.ordered-list-roman li {
  margin-left: 30px;
  list-style-type: lower-roman;
  color: #f8b600;
  font-weight: 500;
  line-height: 1.82em !important;
}

.ordered-list-roman li span {
  font-weight: 300;
  color: #777777;
}

.single-input {
  display: block;
  width: 100%;
  line-height: 40px;
  border: none;
  outline: none;
  background: #fff;
  padding: 0 20px;
}

.single-input:focus {
  outline: none;
}

.input-group-icon {
  position: relative;
}

.input-group-icon .icon {
  position: absolute;
  left: 20px;
  top: 0;
  line-height: 40px;
  z-index: 3;
}

.input-group-icon .icon i {
  color: #797979;
}

.input-group-icon .single-input {
  padding-left: 45px;
}

.single-textarea {
  display: block;
  width: 100%;
  line-height: 40px;
  border: none;
  outline: none;
  background: #fff;
  padding: 0 20px;
  height: 100px;
  resize: none;
}

.single-textarea:focus {
  outline: none;
}

.single-input-primary {
  display: block;
  width: 100%;
  line-height: 40px;
  border: 1px solid transparent;
  outline: none;
  background: #fff;
  padding: 0 20px;
}

.single-input-primary:focus {
  outline: none;
  border: 1px solid #f8b600;
}

.single-input-accent {
  display: block;
  width: 100%;
  line-height: 40px;
  border: 1px solid transparent;
  outline: none;
  background: #fff;
  padding: 0 20px;
}

.single-input-accent:focus {
  outline: none;
  border: 1px solid #eb6b55;
}

.single-input-secondary {
  display: block;
  width: 100%;
  line-height: 40px;
  border: 1px solid transparent;
  outline: none;
  background: #fff;
  padding: 0 20px;
}

.single-input-secondary:focus {
  outline: none;
  border: 1px solid #f09359;
}

.default-switch {
  width: 35px;
  height: 17px;
  border-radius: 8.5px;
  background: #fff;
  position: relative;
  cursor: pointer;
}

.default-switch input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.default-switch input + label {
  position: absolute;
  top: 1px;
  left: 1px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #f8b600;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.default-switch input:checked + label {
  left: 19px;
}

.single-element-widget {
  margin-bottom: 30px;
}

.primary-switch {
  width: 35px;
  height: 17px;
  border-radius: 8.5px;
  background: #fff;
  position: relative;
  cursor: pointer;
}

.primary-switch input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.primary-switch input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.primary-switch input + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  border-radius: 8.5px;
  cursor: pointer;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.primary-switch input + label:after {
  content: "";
  position: absolute;
  top: 1px;
  left: 1px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #fff;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.primary-switch input:checked + label:after {
  left: 19px;
}

.primary-switch input:checked + label:before {
  background: #f8b600;
}

.confirm-switch {
  width: 35px;
  height: 17px;
  border-radius: 8.5px;
  background: #fff;
  position: relative;
  cursor: pointer;
}

.confirm-switch input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.confirm-switch input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.confirm-switch input + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  border-radius: 8.5px;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  cursor: pointer;
}

.confirm-switch input + label:after {
  content: "";
  position: absolute;
  top: 1px;
  left: 1px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #fff;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.confirm-switch input:checked + label:after {
  left: 19px;
}

.confirm-switch input:checked + label:before {
  background: #4cd3e3;
}

.primary-checkbox {
  width: 16px;
  height: 16px;
  border-radius: 3px;
  background: #fff;
  position: relative;
  cursor: pointer;
}

.primary-checkbox input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.primary-checkbox input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid #f1f1f1;
}

.primary-checkbox input:checked + label {
  background: url(../img/elements/primary-check.png) no-repeat center center/cover;
  border: none;
}

.confirm-checkbox {
  width: 16px;
  height: 16px;
  border-radius: 3px;
  background: #fff;
  position: relative;
  cursor: pointer;
}

.confirm-checkbox input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.confirm-checkbox input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid #f1f1f1;
}

.confirm-checkbox input:checked + label {
  background: url(../img/elements/success-check.png) no-repeat center center/cover;
  border: none;
}

.disabled-checkbox {
  width: 16px;
  height: 16px;
  border-radius: 3px;
  background: #fff;
  position: relative;
  cursor: pointer;
}

.disabled-checkbox input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.disabled-checkbox input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid #f1f1f1;
}

.disabled-checkbox input:disabled {
  cursor: not-allowed;
  z-index: 3;
}

.disabled-checkbox input:checked + label {
  background: url(../img/elements/disabled-check.png) no-repeat center center/cover;
  border: none;
}

.primary-radio {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: #fff;
  position: relative;
  cursor: pointer;
}

.primary-radio input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.primary-radio input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #f1f1f1;
}

.primary-radio input:checked + label {
  background: url(../img/elements/primary-radio.png) no-repeat center center/cover;
  border: none;
}

.confirm-radio {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: #fff;
  position: relative;
  cursor: pointer;
}

.confirm-radio input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.confirm-radio input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #f1f1f1;
}

.confirm-radio input:checked + label {
  background: url(../img/elements/success-radio.png) no-repeat center center/cover;
  border: none;
}

.disabled-radio {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: #fff;
  position: relative;
  cursor: pointer;
}

.disabled-radio input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.disabled-radio input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #f1f1f1;
}

.disabled-radio input:disabled {
  cursor: not-allowed;
  z-index: 3;
}

.disabled-radio input:checked + label {
  background: url(../img/elements/disabled-radio.png) no-repeat center center/cover;
  border: none;
}

.default-select {
  height: 40px;
}

.default-select .nice-select {
  border: none;
  border-radius: 0px;
  height: 40px;
  background: #fff;
  padding-left: 20px;
  padding-right: 40px;
}

.default-select .nice-select .list {
  margin-top: 0;
  border: none;
  border-radius: 0px;
  box-shadow: none;
  width: 100%;
  padding: 10px 0 10px 0px;
}

.default-select .nice-select .list .option {
  font-weight: 300;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  line-height: 28px;
  min-height: 28px;
  font-size: 12px;
  padding-left: 20px;
}

.default-select .nice-select .list .option.selected {
  color: #f8b600;
  background: transparent;
}

.default-select .nice-select .list .option:hover {
  color: #f8b600;
  background: transparent;
}

.default-select .current {
  margin-right: 50px;
  font-weight: 300;
}

.default-select .nice-select::after {
  right: 20px;
}

@media (max-width: 991px) {
  .left-align-p p {
    margin-top: 20px;
  }
}

.form-select {
  height: 40px;
  width: 100%;
}

.form-select .nice-select {
  border: none;
  border-radius: 0px;
  height: 40px;
  background: #fff !important;
  padding-left: 45px;
  padding-right: 40px;
  width: 100%;
}

.form-select .nice-select .list {
  margin-top: 0;
  border: none;
  border-radius: 0px;
  box-shadow: none;
  width: 100%;
  padding: 10px 0 10px 0px;
}

.form-select .nice-select .list .option {
  font-weight: 300;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  line-height: 28px;
  min-height: 28px;
  font-size: 12px;
  padding-left: 45px;
}

.form-select .nice-select .list .option.selected {
  color: #f8b600;
  background: transparent;
}

.form-select .nice-select .list .option:hover {
  color: #f8b600;
  background: transparent;
}

.form-select .current {
  margin-right: 50px;
  font-weight: 300;
}

.form-select .nice-select::after {
  right: 20px;
}

/* =================================== */
/*  Menu Styles
/* =================================== */
/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
.header-top {
  font-size: 12px;
  padding: 0px 0px;
}

.header-top a {
  color: #fff;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.header-top a:hover {
  color: #f8b600;
}

.header-top ul li {
  display: inline-block;
  margin-right: 15px;
}

@media (max-width: 414px) {
  .header-top ul li {
    margin-right: 0px;
  }
}

.header-top .header-top-left a {
  margin-right: 8px;
}

.header-top .header-top-right {
  text-align: right;
}

.header-top .header-top-right .header-social a {
  color: #fff;
  margin-left: 15px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.header-top .header-top-right .header-social a:hover {
  color: #f8b600;
}

.top-head-btn {
  background: #f8b600;
  padding: 9px 26px;
}

.main-menu {
  padding-bottom: 20px;
  padding-top: 20px;
  background: rgba(255, 255, 255, 0.15);
  padding-left: 15px;
  padding-right: 15px;
}

#header {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  transition: all 0.5s;
  z-index: 997;
}

#header.header-scrolled {
  transition: all 0.5s;
  background-color: rgba(34, 34, 34, 0.9);
}

#header.header-scrolled .header-top {
  display: none;
}

#header.header-scrolled .main-menu {
  background: transparent;
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/* Nav Menu Essentials */
.nav-menu, .nav-menu * {
  margin: 0;
  padding: 0;
  list-style: none;
}

.nav-menu ul {
  position: absolute;
  display: none;
  top: 100%;
  right: 0;
  z-index: 99;
}

.nav-menu li {
  position: relative;
  white-space: nowrap;
}

.nav-menu > li {
  float: left;
}

.nav-menu li:hover > ul,
.nav-menu li.sfHover > ul {
  display: block;
}

.nav-menu ul ul {
  top: 0;
  right: 100%;
}

.nav-menu ul li {
  min-width: 180px;
}

/* Nav Menu Arrows */
.sf-arrows .sf-with-ul {
  padding-right: 30px;
}

.sf-arrows .sf-with-ul:after {
  content: "\f107";
  position: absolute;
  right: 15px;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
}

.sf-arrows ul .sf-with-ul:after {
  content: "\f105";
}

/* Nav Meu Container */
@media (max-width: 768px) {
  #nav-menu-container {
    display: none;
  }
}

/* Nav Meu Styling */
.nav-menu a {
  padding: 0 8px 0px 8px;
  text-decoration: none;
  display: inline-block;
  color: #fff;
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  outline: none;
}

.nav-menu li:hover > a {
  color: #f8b600;
}

.nav-menu > li {
  margin-left: 10px;
}

.nav-menu ul {
  margin: 22px 0 0 0;
  padding: 10px;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  background: #fff;
}

.nav-menu ul li {
  transition: 0.3s;
}

.nav-menu ul li a {
  padding: 5px 10px;
  color: #333;
  transition: 0.3s;
  display: block;
  font-size: 12px;
  text-transform: none;
}

.nav-menu ul li:hover > a {
  color: #f8b600;
}

.nav-menu ul ul {
  margin-right: 10px;
  margin-top: 0;
}

/* Mobile Nav Toggle */
#mobile-nav-toggle {
  float: right;
  z-index: 999;
  margin-top: 4px;
  border: 0;
  background: none;
  font-size: 24px;
  display: none;
  transition: all 0.4s;
  outline: none;
  cursor: pointer;
}

#mobile-nav-toggle i {
  color: #fff;
  font-weight: 900;
}

@media (max-width: 991px) {
  #mobile-nav-toggle {
    display: inline;
  }
  #nav-menu-container {
    display: none;
  }
}

/* Mobile Nav Styling */
#mobile-nav {
  position: fixed;
  top: 0;
  padding-top: 18px;
  bottom: 0;
  z-index: 998;
  background: rgba(0, 0, 0, 0.8);
  left: -260px;
  width: 260px;
  overflow-y: auto;
  transition: 0.4s;
}

#mobile-nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

#mobile-nav ul li {
  position: relative;
}

#mobile-nav ul li a {
  color: #fff;
  font-size: 13px;
  text-transform: uppercase;
  overflow: hidden;
  padding: 10px 22px 10px 15px;
  position: relative;
  text-decoration: none;
  width: 100%;
  display: block;
  outline: none;
  font-weight: 400;
}

#mobile-nav ul li a:hover {
  color: #fff;
}

#mobile-nav ul li li {
  padding-left: 30px;
}

#mobile-nav ul .menu-has-children i {
  position: absolute;
  right: 0;
  z-index: 99;
  padding: 15px;
  cursor: pointer;
  color: #fff;
}

#mobile-nav ul .menu-has-children i.fa-chevron-up {
  color: #f8b600;
}

#mobile-nav ul .menu-has-children li a {
  text-transform: none;
}

#mobile-nav ul .menu-item-active {
  color: #f8b600;
}

#mobile-body-overly {
  width: 100%;
  height: 100%;
  z-index: 997;
  top: 0;
  left: 0;
  position: fixed;
  background: rgba(0, 0, 0, 0.7);
  display: none;
}

/* Mobile Nav body classes */
body.mobile-nav-active {
  overflow: hidden;
}

body.mobile-nav-active #mobile-nav {
  left: 0;
}

body.mobile-nav-active #mobile-nav-toggle {
  color: #fff;
}

/* =================================== */
/*  Homepage Styles
/* =================================== */
.section-gap {
  padding: 120px 0;
}

.alert-msg {
  color: #008000;
}

#logo {
  padding-left: 15px;
}

#nav-menu-container {
  padding-right: 15px;
}

.primary-btn {
  background: #f8b600;
  line-height: 42px;
  padding-left: 30px;
  padding-right: 30px;
  border: none;
  color: #fff;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  position: relative;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  position: relative;
}

.primary-btn:focus {
  outline: none;
}

.primary-btn span {
  color: #fff;
  position: absolute;
  top: 50%;
  transform: translateY(-60%);
  right: 30px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.primary-btn:hover {
  color: #fff;
}

.primary-btn:hover span {
  color: #fff;
  right: 20px;
}

.primary-btn.white {
  border: 1px solid #fff;
  color: #fff;
}

.primary-btn.white span {
  color: #fff;
}

.primary-btn.white:hover {
  background: #fff;
  color: #f8b600;
}

.primary-btn.white:hover span {
  color: #f8b600;
}

.primary-btn.wh {
  background: #fff;
  color: #222;
  text-transform: uppercase;
}

.primary-btn.wh:hover {
  background: #222;
  color: #fff;
}

.primary-btn.squire {
  border-radius: 0px !important;
  border: 1px solid transparent;
}

.primary-btn.squire:hover {
  border: 1px solid #fff;
  background: transparent;
  color: #fff;
}

.overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.section-title {
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 80px;
}

.section-title h1 {
  margin-bottom: 10px;
}

/*-------- Start Banner Area ------------*/
.banner-area {
  background: url(../img/hero-bg.jpg) center;
  background-size: cover;
}

.banner-area .overlay-bg {
  background: rgba(4, 9, 30, 0.4);
}

@media (max-width: 767px) {
  .banner-area .fullscreen {
    height: auto !important;
  }
}

.banner-left h6 {
  text-transform: uppercase;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 2px;
}

.banner-left h1 {
  margin: 20px 0;
  font-size: 60px;
  text-transform: uppercase;
}

@media (max-width: 1199px) {
  .banner-left h1 {
    font-size: 40px;
  }
}

@media (max-width: 413px) {
  .banner-left h1 {
    font-size: 33px;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .banner-left h1 {
    font-size: 36px;
  }
  .banner-left h6 {
    font-size: 12px;
  }
}

.banner-left p {
  margin: 20px 0px;
}

.banner-left .primary-btn {
  background: #f8b600;
}

.banner-left .primary-btn:hover {
  background: #222;
  color: #fff;
}

@media (max-width: 767px) {
  .banner-left {
    margin-top: 120px;
    margin-bottom: 60px;
  }
}

.banner-right .nav-tabs {
  border-bottom: none;
}

.banner-right .nav-item {
  background: rgba(255, 255, 255, 0.25);
  text-transform: uppercase;
  font-weight: 600;
  font-size: 13px;
  margin-right: 1px;
}

.banner-right .nav-item a {
  color: #fff;
}

.banner-right .nav-item:hover a {
  border-color: transparent !important;
}

.banner-right .nav-tabs .nav-link {
  border-radius: 0 !important;
}

.banner-right .tab-content {
  background: #fff;
}

.banner-right .form-wrap {
  padding: 40px 30px;
  text-align: center;
}

.banner-right .form-wrap .form-control {
  padding: 0.675rem 0.75rem;
  font-size: 13px;
  font-weight: 300;
  border-radius: 0px;
  margin-bottom: 10px;
  border-color: #eee;
}

.banner-right .form-wrap .form-control:focus {
  box-shadow: none;
}

@media (max-width: 1024px) {
  .banner-right {
    margin-top: 40px;
  }
}

@media (max-width: 767px) {
  .banner-right {
    padding-bottom: 50px;
  }
}

/*-------- End Banner Area ------------*/
/*-------- start Popular-destination Area ------------*/
.single-destination {
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.single-destination .overlay-bg {
  background: rgba(4, 9, 30, 0.4);
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  opacity: 0;
}

.single-destination .thumb {
  position: relative;
}

.single-destination .thumb img {
  width: 100%;
}

.single-destination .desc {
  text-align: center;
  position: absolute;
  width: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.single-destination .desc .price-btn {
  background: #f8b600;
  text-align: center;
  color: #222;
  padding: 6px 30px;
  font-size: 14px;
  font-weight: 600;
  display: inline-block;
  margin: 10px;
  opacity: 0;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.single-destination .desc h4 {
  color: #fff;
}

.single-destination .desc p {
  color: #fff;
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 0px;
}

.single-destination:hover .price-btn {
  opacity: 1;
}

.single-destination:hover .overlay-bg {
  opacity: 1;
}

@media (max-width: 991px) {
  .single-destination {
    margin-bottom: 30px;
  }
}

/*-------- end Popular-destination Area ------------*/
/*-------- start price Area ------------*/
.price-area {
  background: url(../img/price-bg.png);
  background-size: cover;
}

.price-area .single-price {
  background: #fff;
  padding: 30px;
}

.price-area .single-price h4 {
  text-align: center;
  border-bottom: 1px solid #f8b600;
  padding-bottom: 20px;
}

.price-area .single-price .price-list {
  margin-top: 30px;
}

.price-area .single-price .price-list li {
  margin-bottom: 10px;
}

.price-area .single-price .price-list li .price-btn {
  background: #f9f9ff;
  border: 1px solid #eee;
  color: #222;
  font-size: 14px;
  font-weight: 600;
  padding: 5px 20px;
}

.price-area .single-price .price-list li .price-btn:hover {
  border-color: #f8b600;
  background: #f8b600;
  color: #fff;
}

@media (max-width: 991px) {
  .price-area .single-price {
    margin-bottom: 30px;
  }
}

/*-------- end price Area ------------*/
/*-------- start other-issue Area ------------*/
.single-other-issue .thumb {
  overflow: hidden;
}

.single-other-issue .thumb img {
  width: 100%;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.single-other-issue h4 {
  margin: 20px 0;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.single-other-issue:hover h4 {
  color: #f8b600;
}

.single-other-issue:hover .thumb img {
  transform: scale(1.08) rotate(-3deg);
}

/*-------- end other-issue Area ------------*/
/*--------- start testimonial area  -------------*/
.testimonial-area {
  background-color: #f9f9ff;
}

.testimonial-area .owl-dots {
  text-align: center;
  bottom: 5px;
  margin-top: 70px;
  width: 100%;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
}

.testimonial-area .owl-dot {
  height: 10px;
  width: 10px;
  display: inline-block;
  background: rgba(248, 182, 0, 0.2);
  margin-left: 5px;
  margin-right: 5px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.testimonial-area .owl-dot.active {
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  background: #f8b600;
}

.single-testimonial {
  background-color: #fff;
  padding: 25px 30px 12px 30px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.single-testimonial:hover {
  cursor: pointer;
}

.single-testimonial .thumb {
  margin-right: 30px;
}

.single-testimonial h4 {
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  margin-bottom: 5px;
}

.single-testimonial .star .checked {
  color: orange;
}

.single-testimonial:hover h4 {
  color: #f8b600;
  cursor: pointer;
}

/*--------- end testimonial area  -------------*/
/*--------- start home-about area  -------------*/
.home-about-area .home-about-left {
  padding-left: 20%;
}

@media (max-width: 1366px) {
  .home-about-area .home-about-left {
    padding-left: 5%;
  }
}

.home-about-area .home-about-left h1 {
  max-width: 510px;
}

@media (max-width: 1366px) {
  .home-about-area .home-about-left h1 {
    font-size: 20px;
  }
}

.home-about-area .home-about-left p {
  max-width: 500px;
  padding: 20px 0;
}

@media (max-width: 991px) {
  .home-about-area .home-about-left p {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
}

.home-about-area .home-about-left .primary-btn {
  margin-top: 10px;
  background: #222;
  font-size: 14px;
}

.home-about-area .home-about-left .primary-btn:hover {
  background: #f8b600;
}

@media (max-width: 991px) {
  .home-about-area .home-about-left {
    padding: 100px 15px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    display: inline-block;
  }
  .home-about-area .home-about-left h1 {
    margin-left: auto;
    margin-right: auto;
    display: inline-block;
  }
}

.home-about-area .home-about-right img {
  width: 100%;
}

/*--------- end home-about area  -------------*/
/*--------- start recent-blog Area -------------*/
.recent-blog-area .owl-dots {
  text-align: center;
  bottom: 5px;
  margin-top: 70px;
  width: 100%;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
}

.recent-blog-area .owl-dot {
  height: 10px;
  width: 10px;
  display: inline-block;
  background: rgba(248, 182, 0, 0.2);
  margin-left: 5px;
  margin-right: 5px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.recent-blog-area .owl-dot.active {
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  background: #f8b600;
}

.recent-blog-area .single-recent-blog-post .thumb {
  overflow: hidden;
}

.recent-blog-area .single-recent-blog-post .thumb img {
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.recent-blog-area .single-recent-blog-post .tags {
  margin: 20px 0px;
}

.recent-blog-area .single-recent-blog-post .tags li {
  display: inline-block;
  border: 1px solid #eee;
  padding: 4px 12px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.recent-blog-area .single-recent-blog-post .tags li:hover {
  background-color: #f8b600;
}

.recent-blog-area .single-recent-blog-post .tags li:hover a {
  color: #fff;
}

.recent-blog-area .single-recent-blog-post .tags li a {
  font-weight: 400;
  font-size: 12px;
  color: #222;
}

.recent-blog-area .single-recent-blog-post .title {
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  margin: 20px 0;
}

.recent-blog-area .single-recent-blog-post .title:hover {
  color: #f8b600;
}

.recent-blog-area .single-recent-blog-post .date {
  color: #222;
  font-weight: 300;
}

@media (max-width: 575px) {
  .recent-blog-area .single-recent-blog-post {
    margin: 15px;
  }
}

.recent-blog-area .single-recent-blog-post:hover .thumb img {
  transform: scale(1.05);
}

/*--------- end recent-blog Area -------------*/
/*-------- Start Common Page Style   -------------*/
.link-nav {
  margin-top: 10px;
  margin-bottom: 0px;
}

.about-banner {
  background: url(../img/top-banner.jpg) center;
  background-size: cover;
}

.about-banner .overlay-bg {
  background: rgba(4, 9, 30, 0.4);
}

.about-content {
  margin-top: 70px;
  padding: 90px 0px;
  text-align: center;
}

@media (max-width: 767px) {
  .about-content {
    margin-top: 70px;
  }
}

.about-content h1 {
  font-size: 48px;
  font-weight: 600;
}

.about-content a {
  color: #fff;
  font-weight: 300;
  font-size: 14px;
}

.about-content .lnr {
  margin: 0px 10px;
  font-weight: 600;
}

/*-------- End Common Page Style   -------------*/
/* =================================== */
/*  About Page Styles
/* =================================== */
/*-------- start about-info Style  -------------*/
.about-info-area .info-left img {
  width: 100%;
}

@media (max-width: 991px) {
  .about-info-area .info-left {
    margin-bottom: 30px;
  }
}

.about-info-area .info-right h6 {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 400;
}

.about-info-area .info-right h1 {
  text-transform: uppercase;
  margin: 20px 0px;
}

.about-info-area .info-right p {
  max-width: 450px;
}

/*-------- end about-info Style  -------------*/
/* =================================== */
/*  Packages Page Styles
/* =================================== */
/*-------- start hot-deal Style  -------------*/
.hot-deal-area .owl-dots {
  text-align: center;
  bottom: 5px;
  margin-top: 70px;
  width: 100%;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
}

.hot-deal-area .owl-dot {
  height: 10px;
  width: 10px;
  display: inline-block;
  background: rgba(248, 182, 0, 0.2);
  margin-left: 5px;
  margin-right: 5px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.hot-deal-area .owl-dot.active {
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  background: #f8b600;
}

.single-carusel .thumb .overlay-bg {
  z-index: 1;
  background: rgba(4, 9, 30, 0.5);
}

.single-carusel .price-detials {
  text-align: center;
  position: absolute;
  width: 100%;
  left: 50%;
  top: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);
}

@media (max-width: 413px) {
  .single-carusel .price-detials {
    top: 35%;
  }
}

.single-carusel .price-detials .price-btn {
  background: #f8b600;
  color: #222;
  font-weight: 200;
  font-size: 14px;
  padding: 10px 30px;
}

.single-carusel .price-detials .price-btn span {
  font-weight: 600;
}

.single-carusel .details {
  text-align: center;
  position: absolute;
  width: 100%;
  left: 50%;
  top: 85%;
  z-index: 2;
  transform: translate(-50%, -50%);
}

/*-------- start hot-deal Style  -------------*/
/*-------- start destinations-area Style  -------------*/
.destinations-area {
  background: #f9f9ff;
}

.destinations-area .single-destinations {
  margin-top: 30px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.destinations-area .single-destinations .thumb {
  overflow: hidden;
}

.destinations-area .single-destinations .thumb img {
  width: 100%;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.destinations-area .single-destinations .details {
  background: #fff;
  padding: 20px;
}

.destinations-area .single-destinations .details h4 {
  margin-bottom: 10px;
}

.destinations-area .single-destinations .details .package-list {
  margin-top: 30px;
}

.destinations-area .single-destinations .details .package-list li {
  border-bottom: 1px solid #eee;
  margin-bottom: 15px;
  padding-bottom: 15px;
}

.destinations-area .single-destinations .details .package-list li:last-child {
  border-bottom: none;
  margin-bottom: 0px;
}

.destinations-area .single-destinations .details .price-btn {
  background: #f8b600;
  color: #222;
  font-weight: 600;
  padding: 5px 20px;
}

.destinations-area .single-destinations:hover {
  box-shadow: 0px 10px 30px 0px rgba(60, 64, 143, 0.2);
}

.destinations-area .star .checked {
  color: orange;
}

/*-------- end destinations-area Style  -------------*/
/* =================================== */
/*  Insurence Page Styles
/* =================================== */
.insurence-right h6, .insurence-left h6 {
  font-weight: 500;
}

.insurence-right h1, .insurence-left h1 {
  margin: 20px 0px;
}

.insurence-right p, .insurence-left p {
  max-width: 445px;
}

.insurence-right img, .insurence-left img {
  width: 100%;
}

.list-wrap {
  background: #fbfcff;
  max-width: 445px;
  padding: 30px;
}

.list-wrap ul {
  list-style-image: url("../img/insurence/bullet.png");
}

.list-wrap li {
  padding-left: 12px;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 15px;
  color: #222;
}

@media (max-width: 991px) {
  .img-one {
    margin-bottom: 45px;
  }
}

/* =================================== */
/*  Blog Styles
/* =================================== */
/*============ Start Blog Home Styles  =============*/
.blog-home-banner {
  background: url(../img/blog/blog-home-banner.jpg) bottom;
  background-size: cover;
  padding: 130px 0px;
}

.blog-home-banner .primary-btn {
  border-radius: 0px;
  background: #fff;
  color: #222;
  font-weight: 600;
  border: 1px solid transparent;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.blog-home-banner .primary-btn:hover {
  background: transparent;
  border-color: #fff;
  color: #fff;
}

.blog-home-banner .overlay-bg {
  background: rgba(0, 0, 0, 0.5);
}

.blog-page {
  background: #f9f9ff;
}

.blog-header-content h1 {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 414px) {
  .blog-header-content h1 {
    font-size: 30px;
  }
}

.blog-header-content p {
  margin: 20px 0px;
}

.top-category-widget-area {
  background-color: #f9f9ff;
}

.top-category-widget-area .single-cat-widget {
  position: relative;
  text-align: center;
}

.top-category-widget-area .single-cat-widget .overlay-bg {
  background: rgba(0, 0, 0, 0.85);
  margin: 7%;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.top-category-widget-area .single-cat-widget img {
  width: 100%;
}

.top-category-widget-area .single-cat-widget .content-details {
  top: 32%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  color: #fff;
  bottom: 0px;
  position: absolute;
}

.top-category-widget-area .single-cat-widget .content-details h4 {
  color: #fff;
}

.top-category-widget-area .single-cat-widget .content-details span {
  display: inline-block;
  background: #fff;
  width: 60%;
  height: 1%;
}

.top-category-widget-area .single-cat-widget:hover .overlay-bg {
  background: rgba(248, 182, 0, 0.85);
  margin: 7%;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

@media (max-width: 991px) {
  .top-category-widget-area .single-cat-widget {
    margin-bottom: 50px;
  }
}

.post-content-area {
  background-color: #f9f9ff;
}

.post-content-area .single-post {
  margin-bottom: 50px;
}

.post-content-area .single-post .meta-details {
  text-align: right;
  margin-top: 35px;
}

@media (max-width: 767px) {
  .post-content-area .single-post .meta-details {
    text-align: left;
  }
}

.post-content-area .single-post .meta-details .tags {
  margin-bottom: 30px;
}

.post-content-area .single-post .meta-details .tags li {
  display: inline-block;
  font-size: 14px;
}

.post-content-area .single-post .meta-details .tags li a {
  color: #222;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.post-content-area .single-post .meta-details .tags li a:hover {
  color: #f8b600;
}

@media (max-width: 1024px) {
  .post-content-area .single-post .meta-details {
    margin-top: 0px;
  }
}

.post-content-area .single-post .user-name a,
.post-content-area .single-post .date a,
.post-content-area .single-post .view a,
.post-content-area .single-post .comments a {
  color: #777777;
  margin-right: 10px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.post-content-area .single-post .user-name a:hover,
.post-content-area .single-post .date a:hover,
.post-content-area .single-post .view a:hover,
.post-content-area .single-post .comments a:hover {
  color: #f8b600;
}

.post-content-area .single-post .user-name .lnr,
.post-content-area .single-post .date .lnr,
.post-content-area .single-post .view .lnr,
.post-content-area .single-post .comments .lnr {
  font-weight: 900;
  color: #222;
}

.post-content-area .single-post .feature-img img {
  width: 100%;
}

.post-content-area .single-post .posts-title h3 {
  margin: 20px 0px;
}

.post-content-area .single-post .excert {
  margin-bottom: 20px;
}

.post-content-area .single-post .primary-btn {
  background: #fff !important;
  color: #222 !important;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  border-radius: 0px !important;
}

.post-content-area .single-post .primary-btn:hover {
  background: #f8b600 !important;
  color: #fff !important;
}

.posts-list .posts-title h3 {
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.posts-list .posts-title h3:hover {
  color: #f8b600;
}

.blog-pagination {
  padding-top: 60px;
  padding-bottom: 120px;
}

.blog-pagination .page-link {
  border-radius: 0;
}

.blog-pagination .page-item {
  border: none;
}

.page-link {
  background: transparent;
  font-weight: 400;
}

.blog-pagination .page-item.active .page-link {
  background-color: #f8b600;
  border-color: transparent;
  color: #fff;
}

.blog-pagination .page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #777777;
  border: none;
}

.blog-pagination .page-link .lnr {
  font-weight: 600;
}

.blog-pagination .page-item:last-child .page-link,
.blog-pagination .page-item:first-child .page-link {
  border-radius: 0;
}

.blog-pagination .page-link:hover {
  color: #fff;
  text-decoration: none;
  background-color: #f8b600;
  border-color: #eee;
}

.sidebar-widgets {
  padding-bottom: 120px;
}

.widget-wrap {
  background: #fafaff;
  padding: 20px 0px;
  border: 1px solid #eee;
}

@media (max-width: 991px) {
  .widget-wrap {
    margin-top: 50px;
  }
}

.widget-wrap .single-sidebar-widget {
  margin: 30px 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #eee;
}

.widget-wrap .single-sidebar-widget:last-child {
  border-bottom: none;
  margin-bottom: 0px;
}

.widget-wrap .search-widget form.search-form input[type=text] {
  color: #fff;
  padding: 10px 22px;
  font-size: 14px;
  border: none;
  float: left;
  width: 80%;
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
  background: #f8b600;
}

.widget-wrap .search-widget ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #fff;
}

.widget-wrap .search-widget ::-moz-placeholder {
  /* Firefox 19+ */
  color: #fff;
}

.widget-wrap .search-widget :-ms-input-placeholder {
  /* IE 10+ */
  color: #fff;
}

.widget-wrap .search-widget :-moz-placeholder {
  /* Firefox 18- */
  color: #fff;
}

.widget-wrap .search-widget form.search-form button {
  float: left;
  width: 20%;
  padding: 10px;
  background: #f8b600;
  color: #fff;
  font-size: 17px;
  border: none;
  cursor: pointer;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
}

.widget-wrap .search-widget form.search-form::after {
  content: "";
  clear: both;
  display: table;
}

.widget-wrap .user-info-widget {
  text-align: center;
}

.widget-wrap .user-info-widget a h4 {
  margin-top: 40px;
  margin-bottom: 5px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.widget-wrap .user-info-widget .social-links li {
  display: inline-block;
  margin-bottom: 10px;
}

.widget-wrap .user-info-widget .social-links li a {
  color: #222;
  padding: 10px;
  font-size: 14px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.widget-wrap .user-info-widget .social-links li a:hover {
  color: #f8b600;
}

.widget-wrap .popular-post-widget .popular-title {
  background: #f8b600;
  color: #fff;
  text-align: center;
  padding: 12px 0px;
}

.widget-wrap .popular-post-widget .popular-post-list {
  margin-top: 30px;
}

.widget-wrap .popular-post-widget .popular-post-list .thumb img {
  width: 100%;
}

.widget-wrap .popular-post-widget .single-post-list {
  margin-bottom: 20px;
}

.widget-wrap .popular-post-widget .single-post-list .details {
  margin-left: 12px;
}

.widget-wrap .popular-post-widget .single-post-list .details h6 {
  font-weight: 600;
  margin-bottom: 10px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.widget-wrap .popular-post-widget .single-post-list .details h6:hover {
  color: #f8b600;
}

.widget-wrap .popular-post-widget .single-post-list .details p {
  margin-bottom: 0px;
}

.widget-wrap .ads-widget img {
  width: 100%;
}

.widget-wrap .post-category-widget .category-title {
  background: #f8b600;
  color: #fff;
  text-align: center;
  padding: 12px 0px;
}

.widget-wrap .post-category-widget .cat-list li {
  padding-top: 15px;
  padding-bottom: 8px;
  border-bottom: 2px dotted #eee;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.widget-wrap .post-category-widget .cat-list li p {
  font-weight: 300;
  font-size: 14px;
  margin-bottom: 0px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.widget-wrap .post-category-widget .cat-list li a {
  color: #777777;
}

.widget-wrap .post-category-widget .cat-list li:hover {
  border-color: #f8b600;
}

.widget-wrap .post-category-widget .cat-list li:hover p {
  color: #f8b600;
}

.widget-wrap .newsletter-widget .newsletter-title {
  background: #f8b600;
  color: #fff;
  text-align: center;
  padding: 12px 0px;
}

.widget-wrap .newsletter-widget .col-autos {
  width: 100%;
}

.widget-wrap .newsletter-widget p {
  text-align: center;
  margin: 20px 0px;
}

.widget-wrap .newsletter-widget .bbtns {
  background: #f8b600;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  padding: 8px 20px;
  border-radius: 0;
}

.widget-wrap .newsletter-widget .input-group-text {
  background: #fff;
  border-radius: 0px;
  vertical-align: top;
}

.widget-wrap .newsletter-widget .input-group-text i {
  color: #ccc;
  margin-top: -1px;
}

.widget-wrap .newsletter-widget .form-control {
  border-left: none !important;
  border-right: none !important;
  border-radius: 0px;
  font-size: 13px;
  color: #ccc;
  padding: 9px;
  padding-left: 0px;
}

.widget-wrap .newsletter-widget .form-control:focus {
  box-shadow: none;
  border-color: #ccc;
}

.widget-wrap .newsletter-widget ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-size: 13px;
  color: #ccc;
}

.widget-wrap .newsletter-widget ::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 13px;
  color: #ccc;
}

.widget-wrap .newsletter-widget :-ms-input-placeholder {
  /* IE 10+ */
  font-size: 13px;
  color: #ccc;
}

.widget-wrap .newsletter-widget :-moz-placeholder {
  /* Firefox 18- */
  font-size: 13px;
  color: #ccc;
}

.widget-wrap .newsletter-widget .text-bottom {
  margin-bottom: 0px;
}

.widget-wrap .tag-cloud-widget .tagcloud-title {
  background: #f8b600;
  color: #fff;
  text-align: center;
  padding: 12px 0px;
  margin-bottom: 30px;
}

.widget-wrap .tag-cloud-widget ul li {
  display: inline-block;
  border: 1px solid #eee;
  background: #fff;
  padding: 4px 14px;
  margin-bottom: 10px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.widget-wrap .tag-cloud-widget ul li:hover {
  background: #f8b600;
}

.widget-wrap .tag-cloud-widget ul li:hover a {
  color: #fff;
}

.widget-wrap .tag-cloud-widget ul li a {
  font-size: 12px;
  color: #222;
}

/*============ End Blog Home Styles  =============*/
/*============ Start Blog Single Styles  =============*/
.single-post-area {
  padding-top: 80px;
  padding-bottom: 80px;
}

.single-post-area .meta-details {
  margin-top: 20px !important;
}

.single-post-area .social-links li {
  display: inline-block;
  margin-bottom: 10px;
}

.single-post-area .social-links li a {
  color: #222;
  padding: 7px;
  font-size: 14px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.single-post-area .social-links li a:hover {
  color: #f8b600;
}

.single-post-area .quotes {
  margin-top: 20px;
  padding: 30px;
  background-color: white;
  box-shadow: -20.84px 21.58px 30px 0px rgba(176, 176, 176, 0.1);
}

.single-post-area .arrow {
  position: absolute;
}

.single-post-area .arrow .lnr {
  font-size: 20px;
  font-weight: 600;
}

.single-post-area .thumb .overlay-bg {
  background: rgba(0, 0, 0, 0.8);
}

.single-post-area .navigation-area {
  border-top: 1px solid #eee;
  padding-top: 30px;
}

.single-post-area .navigation-area .nav-left {
  text-align: left;
}

.single-post-area .navigation-area .nav-left .thumb {
  margin-right: 20px;
  background: #000;
}

.single-post-area .navigation-area .nav-left .thumb img {
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.single-post-area .navigation-area .nav-left .lnr {
  margin-left: 20px;
  opacity: 0;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.single-post-area .navigation-area .nav-left:hover .lnr {
  opacity: 1;
}

.single-post-area .navigation-area .nav-left:hover .thumb img {
  opacity: .5;
}

@media (max-width: 767px) {
  .single-post-area .navigation-area .nav-left {
    margin-bottom: 30px;
  }
}

.single-post-area .navigation-area .nav-right {
  text-align: right;
}

.single-post-area .navigation-area .nav-right .thumb {
  margin-left: 20px;
  background: #000;
}

.single-post-area .navigation-area .nav-right .thumb img {
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.single-post-area .navigation-area .nav-right .lnr {
  margin-right: 20px;
  opacity: 0;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.single-post-area .navigation-area .nav-right:hover .lnr {
  opacity: 1;
}

.single-post-area .navigation-area .nav-right:hover .thumb img {
  opacity: .5;
}

@media (max-width: 991px) {
  .single-post-area .sidebar-widgets {
    padding-bottom: 0px;
  }
}

.comments-area {
  background: #fafaff;
  border: 1px solid #eee;
  padding: 50px 20px;
  margin-top: 50px;
}

@media (max-width: 414px) {
  .comments-area {
    padding: 50px 8px;
  }
}

.comments-area h4 {
  text-align: center;
  margin-bottom: 50px;
}

.comments-area a {
  color: #222;
}

.comments-area .comment-list {
  padding-bottom: 30px;
}

.comments-area .comment-list:last-child {
  padding-bottom: 0px;
}

.comments-area .comment-list.left-padding {
  padding-left: 25px;
}

@media (max-width: 413px) {
  .comments-area .comment-list .single-comment h5 {
    font-size: 12px;
  }
  .comments-area .comment-list .single-comment .date {
    font-size: 11px;
  }
  .comments-area .comment-list .single-comment .comment {
    font-size: 10px;
  }
}

.comments-area .thumb {
  margin-right: 20px;
}

.comments-area .date {
  font-size: 13px;
  color: #cccccc;
}

.comments-area .comment {
  color: #777777;
}

.comments-area .btn-reply {
  background-color: #fff;
  color: #222;
  border: 1px solid #eee;
  padding: 8px 30px;
  display: block;
  font-weight: 600;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.comments-area .btn-reply:hover {
  background-color: #f8b600;
  color: #fff;
}

.comment-form {
  background: #fafaff;
  text-align: center;
  border: 1px solid #eee;
  padding: 50px 20px;
  margin-top: 50px;
}

.comment-form h4 {
  text-align: center;
  margin-bottom: 50px;
}

.comment-form .name {
  padding-left: 0px;
}

@media (max-width: 991px) {
  .comment-form .name {
    padding-right: 0px;
  }
}

.comment-form .email {
  padding-right: 0px;
}

@media (max-width: 991px) {
  .comment-form .email {
    padding-left: 0px;
  }
}

.comment-form .form-control {
  padding: 12px;
  background: #fff;
  border: none;
  border-radius: 0px;
  width: 100%;
  font-size: 13px;
  color: #777777;
}

.comment-form .form-control:focus {
  box-shadow: none;
  border: 1px solid #eee;
}

.comment-form ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-size: 13px;
  color: #777;
}

.comment-form ::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 13px;
  color: #777;
}

.comment-form :-ms-input-placeholder {
  /* IE 10+ */
  font-size: 13px;
  color: #777;
}

.comment-form :-moz-placeholder {
  /* Firefox 18- */
  font-size: 13px;
  color: #777;
}

.comment-form .primary-btn {
  border-radius: 0px !important;
  border: 1px solid transparent;
  color: #fff;
  font-weight: 600;
}

.comment-form .primary-btn:hover {
  background: transparent;
  border-color: #f8b600 !important;
  color: #f8b600 !important;
}

/*============ End Blog Single Styles  =============*/
/* =================================== */
/*  Contact Page Styles
/* =================================== */
.contact-page-area {
  background: #f9f9ff;
}

.contact-page-area .map-wrap {
  margin-bottom: 120px;
}

@media (max-width: 991px) {
  .contact-page-area .map-wrap {
    margin-bottom: 50px;
  }
}

.contact-page-area .contact-btns {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  padding: 40px;
  color: #fff;
  margin-bottom: 30px;
}

.contact-page-area .contact-btns:hover {
  color: #fff;
}

.contact-page-area .form-area .form-control:focus {
  box-shadow: none !important;
  border-color: #ced4da !important;
}

.contact-page-area .form-area input {
  padding: 15px;
}

.contact-page-area .form-area input,
.contact-page-area .form-area textarea {
  border-radius: 0;
  font-size: 13px !important;
}

.contact-page-area .form-area textarea {
  height: 190px;
  margin-top: 0px;
  padding: 15px;
}

.contact-page-area .form-area .genric-btn {
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  border-radius: 0 !important;
  font-size: 14px;
  text-transform: uppercase !important;
}

.contact-page-area .form-area .genric-btn:hover {
  border: 1px solid #f8b600;
  color: #f8b600 !important;
}

@media (max-width: 991px) {
  .contact-page-area .address-wrap {
    margin-bottom: 30px;
  }
}

.contact-page-area .address-wrap .single-contact-address {
  margin-bottom: 8px;
}

.contact-page-area .address-wrap .single-contact-address h5 {
  font-weight: 300;
  font-size: 16px;
  margin-bottom: 5px;
}

.contact-page-area .address-wrap .single-contact-address .lnr {
  font-size: 30px;
  font-weight: 500;
  color: #f8b600;
  margin-right: 30px;
}

/* =================================== */
/*  Footer Styles
/* =================================== */
.footer-area {
  background: #04091e;
  padding-top: 100px;
}

.footer-area .footer-nav li {
  margin-top: 18px;
}

.footer-area .footer-nav li a {
  color: #fff;
}

.footer-area .footer-nav li a:hover {
  color: #f8b600;
}

.footer-area .primary-btn {
  background-color: #f8b600;
  line-height: 42px;
  padding-left: 30px;
  padding-right: 60px;
  border-radius: 25px;
  border: none;
  color: #fff;
  display: inline-block;
  font-weight: 500;
  position: relative;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  text-transform: uppercase;
  position: relative;
}

.footer-area .primary-btn:focus {
  outline: none;
}

.footer-area .primary-btn span {
  color: #fff;
  position: absolute;
  top: 50%;
  transform: translateY(-60%);
  right: 30px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.footer-area .primary-btn:hover {
  background-color: #f8b600;
  box-shadow: none;
  color: #fff;
}

.footer-area .primary-btn:hover span {
  color: #fff;
  right: 20px;
}

.footer-area .primary-btn.white {
  border: 1px solid #fff;
  color: #fff;
}

.footer-area .primary-btn.white span {
  color: #fff;
}

.footer-area .primary-btn.white:hover {
  background: #fff;
  color: #f8b600;
}

.footer-area .primary-btn.white:hover span {
  color: #f8b600;
}

.footer-area h6 {
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 25px;
  font-size: 16px;
  font-weight: 600;
}

.copy-right-text i, .copy-right-text a {
  color: #f8b600;
}

.instafeed {
  margin: -5px;
}

.instafeed li {
  overflow: hidden;
  width: 25%;
}

.instafeed li img {
  margin: 5px;
}

.footer-social {
  text-align: right;
}

.footer-social a {
  width: 20px;
  width: 40px;
  display: inline-table;
  height: 40px;
  text-align: center;
  padding-top: 10px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.footer-social a:hover i {
  color: #f8b600;
}

.footer-social i {
  color: #cccccc;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

@media (max-width: 991.98px) {
  .footer-social {
    text-align: left;
    margin-top: 20px;
  }
}

.single-footer-widget {
  color: #fff;
}

.single-footer-widget input {
  line-height: 38px;
  border: none;
  background: transparent;
  border: 1px solid #333;
  font-weight: 300;
  color: #777;
  padding-left: 20px;
  width: 80%;
}

.single-footer-widget .bb-btn {
  background-color: #f8b600;
  color: #fff;
  font-weight: 300;
  border-radius: 0;
  cursor: pointer;
}

@media (max-width: 960px) {
  .single-footer-widget .nw-btn {
    margin-top: 20px;
  }
  .single-footer-widget .newsletter {
    padding-bottom: 40px;
  }
}

.single-footer-widget .info {
  position: absolute;
  margin-top: 20px;
  color: #fff;
  font-size: 12px;
}

.single-footer-widget .info.valid {
  color: green;
}

.single-footer-widget .info.error {
  color: red;
}

.single-footer-widget ::-moz-selection {
  /* Code for Firefox */
  background-color: #f8b600;
  color: #777777;
}

.single-footer-widget ::selection {
  background-color: #f8b600;
  color: #777777;
}

.single-footer-widget ::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #777777;
  font-weight: 300;
}

.single-footer-widget :-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #777777;
  opacity: 1;
  font-weight: 300;
}

.single-footer-widget ::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #777777;
  opacity: 1;
  font-weight: 300;
}

.single-footer-widget :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #777777;
  font-weight: 300;
}

.single-footer-widget ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #777777;
  font-weight: 300;
}

@media (max-width: 991.98px) {
  .single-footer-widget {
    margin-bottom: 70px;
  }
}

.single-footer-widget p {
  color: #777777;
}

.single-footer-widget ul li {
  margin-bottom: 12px;
}

.single-footer-widget ul li a {
  color: #777777;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.single-footer-widget ul li a:hover {
  color: #f8b600;
}

.footer-bottom {
  padding-top: 40px;
  margin-top: 20px;
  border-top: 1px solid #333;
}

.footer-text a, .footer-text i {
  color: #f8b600;
}



.main-wrapper-auto-map {
  height: 200px;
 
}
.info-wrapper-auto-map {
  margin-top: 15px;
  margin-bottom: 15px;
}
.map-details-auto-map {
  text-align: left;
  font-size: 1.2em;
}
.map-details-auto-map span {
  font-weight: bold;
}
.search-input-auto-map {
  font-size: 1.2em;
  width: 80%;
}















.download-table-xls-button-style{
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
  text-decoration: none;
  color: #333;
  background-color: #fff;
  border-color: #ccc;
  margin-top: 20px;
  margin-left: 10px;
}